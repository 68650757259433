import React from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'gatsby'

import IndexLayout from 'components/IndexLayout'
import SEO from 'components/seo'
import {
  StripeCheckoutRedirectContainer,
  ShapeContainer,
  StripeCheckoutContentContainer,
  StripeCheckoutRedirectHeader,
  StripeCheckoutImage,
  StripeCheckoutRedirectDescription,
  StripeCheckoutRedirectButton,
} from 'components/StripeCheckout'
import LottieShape from 'components/animations/LottieShape'
import CongratulationsAnimation from 'components/animations/abstract/CongratulationsAnimation'
import success from 'images/png/success.png'

const Success = () => {
  const { t } = useTranslation('paymentSuccess')

  return (
    <IndexLayout>
      <SEO title={t('title')} />
      <StripeCheckoutRedirectContainer>
        <ShapeContainer>
          <LottieShape size={500} animationData={CongratulationsAnimation} />
        </ShapeContainer>
        <StripeCheckoutContentContainer>
          <StripeCheckoutRedirectHeader>
            <StripeCheckoutImage src={success} alt={t('altImageCheckout')} />
            {t('heading')}
          </StripeCheckoutRedirectHeader>
          <StripeCheckoutRedirectDescription>
            {t('message')}
          </StripeCheckoutRedirectDescription>
          <StripeCheckoutRedirectDescription>
            {t('support')}{' '}
            <a href={`mailto:${process.env.GATSBY_SUPPORT_EMAIL}`}>
              {`${process.env.GATSBY_SUPPORT_EMAIL}`}
            </a>
          </StripeCheckoutRedirectDescription>
          <Link
            style={{ boxShadow: 'none' }}
            to={process.env.GATSBY_SITE_ACCOUNT_PATH}
          >
            <StripeCheckoutRedirectButton>
              {t('actionButton')}
            </StripeCheckoutRedirectButton>
          </Link>
        </StripeCheckoutContentContainer>
      </StripeCheckoutRedirectContainer>
    </IndexLayout>
  )
}

export default Success
